import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import { displayMonth } from '../utils/dateformat';

function EventTemplate({
  pageContext: {
    title,
    url,
    image,
    status,
    clock,
    startDate,
    promoter,
    city,
    postalCode,
  },
}) {
  const handleDate = (date) => {
    const dates = date.split('-');
    const day = dates[2];
    const month = dates[1];
    const year = dates[0];

    return `${day}. ${displayMonth(month)} ${year}`;
  };

  const handleClock = (localTime) => {
    if (!localTime) return null;

    const [hour, minute] = localTime.split(':');
    return `At ${hour}.${minute}`;
  };

  const handleStatus = () => {
    if (status !== 'onsale') return 'Not available';
    return 'Buy ticket';
  };

  const handleImage = () => {
    const eventImg = image.filter((e) => e.width === 640);
    return eventImg[0].url;
  };

  return (
    <>
      <SEO
        title={title}
        description={`${title} | Find out when and where you can get tickets using Zooticket`}
      />
      <Layout withoutHeader>
        <div className="event">
          <img className="event__image" src={handleImage()} alt={title} />
          <h1 className="title">{title}</h1>
          <div className="event__info">
            <div className="event__info-left">
              <h2 className="event__promoter">{promoter}</h2>
              <h2 className="event__date">{handleDate(startDate)}</h2>
              <h2 className="event__date">{handleClock(clock)}</h2>
              <h2 className="event__date">
                {postalCode} {city}
              </h2>
            </div>
            <div className="event__info-right">
              <a
                className={`btn event__link info__buyticket-link${
                  title.toLowerCase().match('udsolgt') ||
                  title.toLowerCase().match('aflyst') ||
                  status !== 'onsale'
                    ? ' offsale'
                    : ' onsale'
                }`}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{handleStatus()}</span>
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default EventTemplate;
